
const zh_CN = {
    "global.webName": "e-com365.com",
    "global.searchBtn": "查询",
    "global.addBtn": "新增",
    "global.backBtn": "返回",
    "global.male": "男",
    "global.female": "女",
    "global.apiError": "未知错误，请重试",
    "global.apiEditSuccess": "修改成功!",
    "global.apiaddSuccess": "创建成功!",
    "global.apidelSuccess": "删除成功!",
    "global.apisendSuccess": "发送成功!",
    "global.confirmDelModalTitle": "Delete Items",

    "form.isRequired": "{name}是必填项!",
    "form.isValid": "请输入有效的{name}",
    "form.select": "请选择",
    "form.cancel": "取消",
    "form.ok": "保存",
    "form.delete": "Delete",
    "form.okBtn": "OK",

    "Login.panelName": "登录",
    "Login.required_userName": "请输入用户名",
    "Login.input_username": "用户名",
    "Login.required_password": "请输入密码",
    "Login.input_password": "密码",
    "Login.loginBtn": "登录",
    "Login.panelLeftTitle": "Welcome back to E-Com365 Go CRM Micro-service",
    "Login.panelLeftSpan": "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu",
    "Login.panelRightSpan": "Don’t have an account?",
    "Login.panelRightSpanButton": "Register a new account",
    "Login.panelRightBottom": "Copyright @ 2024 BCNetcom. All Rights Reserved",
    "Login.rememberMeLabel": "记住密码",
    "Login.forgetBtn": "忘记密码？",
    
    "DashboardHeader.labelTips": "Let’s get creative with E-Com365 Go CRM Micro-service to organize your content.",

    "router.dashboard": "Dashboard",
    "router.customer": "Customer",
    "router.customerSegment": "Customer Segment",
    "router.customerAttribute": "Customer Attribute",
    "router.customerSubscribe": "Customer Subscribers",
    "router.customerGroup": "Customer Group",
    "router.campaigns": "Campaigns",
    "router.campaignSettings": "Campaign Settings",
    "router.whatsappTemplate": "Whatsapp Template",
    "router.reports": "Reports",
    "router.orders": "Orders",
    "router.ordersConfig": "Order Config",
    "router.promotionSettings": "Promotion Settings",
    "router.login": "Login",
    "router.forgetpassword": "Forget Password",
    "router.resetpassword": "Reset Password",
    "router.passwordResetSuccess": "Reset Password Success",
    "router.PageBuilder":"Page Builder",

    "homeMenu.dashboard": "首页",
    "homeMenu.customer": "用户管理",
    "homeMenu.allCustomers": "All Customers",
    "homeMenu.segment": "Segment",
    "homeMenu.customerAttribute": "Attribute",
    "homeMenu.customerSubscribe": "Subscribers",
    "homeMenu.customerSegment": "Customer Segment",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.journeys": "Journeys",
    "homeMenu.journey": "Journey",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.whatsappTemplate": "Whatsapp Template",
    "homeMenu.wTemplateCreate": "Create Template",
    "homeMenu.promotion": "Promotion",
    "homeMenu.orders": "Orders",
    "homeMenu.customerConfig": "Config",
    "homeMenu.allOrders": "All Orders",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "首页",
    "homeMenu.push": "消息推送记录",
    "homeMenu.company": "公司管理",
    "homeMenu.role": "角色管理",
    "homeMenu.user": "管理员",
    "homeUser.loginout": "退出登录",
    "homeMenu.PageBuilder": "Page Builder",
    "homeMenu.allPageBuilder": "All Pages",
    "homeMenu.reward": 'Reward',
    "homeMenu.warranty": "Warranty",
    "homeMenu.warrantyRecord": "Warranty Records",
    "homeMenu.warrantyProduct": "Products Management",
    "homeMenu.warrantyForm": "Registration Form",

    "Customer.pushBtn": "推送消息",
    "Customer.pushPromoBtn": "推送优惠",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "请点击右边按钮选择公司",
    "Customer.addCustomerTitle": "新增用户信息",
    "Customer.editCustomerTitle": "编辑用户信息",
    "Customer.detailCustomerTitle": "Detail Customer",
    "Customer.selectCompanyTitle": "选择公司",
    "Customer.editPushMessage": "配置推送消息",
    "Customer.pushModal.okBtn": "发送",
    "customer.confirmModalDelMsg": "确认删除id等于{val}的用户信息？",
    "customer.selectCompanyWarning": "请选择公司!",
    "customer.confirmModalPromoMsg": "确认向{val}位用户推送促销信息？",
    "customer.addFirstCustomerTitle": "Add First Customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add Customer",
    "customer.addFirstCustomerReadMore": "Read More",
    
    "createCustomer.backLabel": "Back",
    
    "detailCustomer.editInfoButton": "Edit information",

    "Attribute.createAttributeTitle": "Create Attribute",
    "Attribute.editAttributeTitle": "Edit Attribute",
    "Attribute.detailAttributeTitle": "Attribute Detail",

    "Segment.addSegmentTitle": "Create Segment",
    "Segment.detailSegmentTitle": "Detail Segment",
    "Segment.editSegmentTitle": "Edit Segment",
    "Segment.confirmModalDelMsg": "Are you sure to delete segment data with id equal {val}?",

    "Subscribe.createTitle": "Create Subscriber",
    "Subscribe.editTitle": "Edit Subscriber",
    
    "campaigns.addNewCampaignLabel": "Create New Campaigns",
    "campaigns.saveButton": "Save Campaign",
    "campaigns.editCampaignLabel": "Edit The Campaign",
    "campaigns.sendCampaignLabel": "Send Campaign",
    "campaigns.addNewJourneyLabel": "Create New Journey",
    "campaigns.saveJourneyButton": "Save Journey",
    "campaigns.editJourneyLabel": "Edit The Journey",
    "campaigns.sendJourneyLabel": "Send Journey",

    "whatsappTemplate.addNewDataLabel": "Create New Template",

    "Order.addOrderTitle": "Create Order",
    "Order.detailOrderTitle": "Information",
    "Order.OrderInvoiceTitle": "Invoice",
    "Order.OrderShipmentTitle": "Shipment",
    "Order.editOrderTitle": "Edit Order",
    "Order.addFirstOrderTitle": "No Orders Yet",
    "Order.addFirstOrderLabel1": "All the upcoming orders from your store will be visible in this page.",
    "Order.addFirstOrderLabel2": "You can add orders by yourself if you sell offline.",
    "Order.addFirstOrder": "Add Order",

    "rewardPoints.rewardRules": "Reward Rules",
    "rewardPoints.createRewardRuleTitle": "Create Rule",
    "rewardPoints.editRewardRuleTitle": "Detail Rule",
    "rewardRule.confirmModalDelMsg": "Are you sure to delete rule data with id equal {val}?",

    "Config.createTitle": "Create Config",
    "Config.editTitle": "Edit Config",

    "PageBuilder.detailPageTitle": "Detail Page",
    "PageBuilder.addPageTitle": "Create Page",
    
    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer Growth",
    "exports.salesGoal": "Sales Goal",
    "exports.conversionRate": "Conversion Rate",
    "exports.storeFunnel": "Store Funnel",
    "exports.ageDistribution": "Age Distribution",
    "exports.averageOrderValue": "Average Order Value",

    "company.addCompanyTitle": "新增公司信息",
    "company.editCompanyTitle": "编辑公司信息",
    "company.confirmModalDelMsg": "确认删除id等于{val}的公司信息？",
    
    "Message.PushDetailTitle": "消息详情",
    "Message.PushTargetTitle": "消息推送目标",
    "Message.notFoundDetail": "未知的消息",
    "MessageModal.text_isRequired": "请输入内容或移除本输入框",

    "pushModal.addTextBtn": "添加新文本",
    "pushModal.addImageBtn": "上传图片",
    "pushModal.addImagePlaceHolder": "输入图片链接或上传图片(png/jpg/jpeg), 多条链接用[ , ]分隔",
    "pushModal.addWeImagePlaceHolder": "输入图片链接或上传图片(png/jpg), 多条链接用[ , ]分隔",
    "pushModal.uploadImageTypeError": "{name}不是有效的图片类型",
    "pushModal.addVideoBtn": "上传视频",
    "pushModal.addVideoPlaceHolder": "输入视频链接或上传视频(mp4/avi/mov/flv), 多条链接用[ , ]分隔",
    "pushModal.addWeVideoPlaceHolder": "输入视频链接或上传视频(mp4), 多条链接用[ , ]分隔",
    "pushModal.uploadVideoTypeError": "{name}不是有效的视频类型",
    "pushModal.addDocumentBtn": "上传文档",
    "pushModal.addDocumentPlaceHolder": "输入文档链接或上传文档(doc/docx/pdf/xls/xlsx/ppt/pptx), 多条链接用[ , ]分隔",
    "pushModal.uploadDocumentTypeError": "{name}不是有效的文档类型",
    "pushModal.uploadSize": "文件最大不得超过30M",

    "role.addRoleTitle": "Create Role",
    "role.editRoleTitle": "Edit Role",
    "role.confirmModalDelMsg": "Are you sure to delete role data with RoleName equal {val}?",

    "user.addUserTitle": "Create User",
    "user.editUserTitle": "Edit User",
    "user.confirmModalDelMsg": "Are you sure to delete user data with Uid equal {val}?",

    "warrantyForm.formTitle": "Registration Form Management",
    "warrantyForm.formPreviewLabel": "Preview",
    "warrantyForm.formEditLabel": "Edit",
    "warrantyForm.categoryPlaceholder": "- Select product category -",
    "warrantyForm.seriesPlaceholder": "- Select product series -",
    "warrantyForm.skuPlaceholder": "- Model No.(SKU) -",
    "warrantyForm.uploadBtn": "上传",
    "warrantyForm.uploadSizeTips": "上传的文件大小必须小于5MB",
    "warrantyForm.uploadTypeTips": "允许上传的文件类型: png gif jpg jpeg pdf.",
    "warrantyForm.isEmail": "请输入有效的邮箱账号"

}
export {
    zh_CN
}   