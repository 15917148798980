import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo, setPermissions, setMenuList, setSubscribedServiceList } from './redux/userSlice'
import { useNavigate, useLocation } from 'react-router-dom';
import { getMenuList, getMerchantSubscribedServiceList, getMerchantPermissions } from './api/menuApi';
import { getProfile } from './api/loginApi';

function UserProvider() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSignedIn } = useSelector((state) => state.user);
    const [loginToken, setLoginToken] = useState(localStorage.getItem("LOGIN_TOKEN"));

    useEffect(() => {
        const params = new URLSearchParams(window.location.href);
        const { redirectToken } = location.state || {};
        const paramsLoginToken = params.get("token") || redirectToken;
        const storageLoginToken = localStorage.getItem("LOGIN_TOKEN");

        setLoginToken(paramsLoginToken || storageLoginToken);
    }, [location.state]);

    useEffect(() => {
        const pathname = window.location.pathname + window.location.search + window.location.hash;
        if (loginToken) {
            localStorage.setItem("LOGIN_TOKEN", loginToken);
            getProfile().then(resProfile => {
                if (resProfile && resProfile.response.isSuccess) {
                    dispatch(setUserInfo({
                        ...resProfile.user,
                        merchant: resProfile.merchant,
                        hasMarketplaceService: resProfile.hasMarketplaceService,
                        hasReleatedMarkets: resProfile.hasReleatedMarkets,
                        isVendorLogin: resProfile.isVendorLogin,
                    }))
                    dispatch(setToken(loginToken))
                    dispatch(setIsLoginCompleted(true))

                    const currentPath = pathname.replace(process.env.REACT_APP_BASE_NAME, '')

                    navigate(currentPath, { replace: true })
                } else {
                    dispatch(setIsLoginCompleted(true))
                }

            }).catch(error => {
                console.error(error)
                dispatch(setIsLoginCompleted(true))
            })
        } else if (!loginToken && !pathname.includes("/login") && !pathname.includes("/forgetpassword") && !pathname.includes("/resetpassword") && !pathname.includes("/resetpassword/success")) {
            // if (process.env.NODE_ENV == "production") {
            //     window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login`
            // } else {
            //     navigate("/login", { replace: true })
            // }
            window.location.href = `${window.location.origin}/login`;

        }
    }, [loginToken]);

    useEffect(() => {
        if (isSignedIn) {
            getMenuList().then(res => {
                if (res && res.menu) {
                    dispatch(setMenuList(res.menu.children))
                }
                else {
                    dispatch(setMenuList([]))
                }
            }).catch(error => {
                console.error(error)
            })

            getMerchantSubscribedServiceList(
                {
                    "pageNumber": 1,
                    "pageSize": 1000,
                    "filters": [
                        {
                            "fieldName": "instance_id",
                            "operator": ">",
                            "value": "0"
                        }
                    ],
                    "sort": {
                        "fieldName": "instance_id",
                        "sort": "asc"
                    }
                }
            ).then(res => {
                if (res && res.users) {
                    localStorage.setItem('subscribedServiceList', JSON.stringify(res.users)
                    )
                    dispatch(setSubscribedServiceList(res.users))

                }
                else {
                    localStorage.setItem('subscribedServiceList', [])

                    dispatch(setSubscribedServiceList("[]"))
                }
            }).catch(error => {
                console.error(error)
            })

            getMerchantPermissions().then(res => {
                if (res && res.permission) {
                    dispatch(setPermissions(res.permission))
                }
                else {
                    dispatch(setPermissions([]))
                }
            }).catch(error => {
                console.error(error)
            })



        }

    }, [isSignedIn])

    return (
        <></>
    );
}

export default UserProvider;
