
const en_US = {
    "global.webName": "E-Com365  Go CRM Micro-service",
    "global.searchBtn": "Filter",
    "global.addBtn": "Create",
    "global.backBtn": "Back",
    "global.male": "Male",
    "global.female": "Female",
    "global.apiError": "unknown error, please try again",
    "global.apiEditSuccess": "edit success!",
    "global.apiaddSuccess": "create success!",
    "global.apidelSuccess": "delete success!",
    "global.apisendSuccess": "send success!",
    "global.confirmDelModalTitle": "Delete Items",
    "global.systemLandingTitle": "Welcome Back !",
    "global.systemLandingMessage": "E-Com365 provide you with advanced AI business analysis and powerful CRM system!",
    "global.delete": "Delete",

    "form.isRequired": "{name} is required!",
    "form.isValid": "please input a valid {name}",
    "form.placeholder": "Please enter {name}",
    "form.select": "select",
    "form.cancel": "Cancel",
    "form.ok": "Save",
    "form.delete": "Delete",
    "form.okBtn": "OK",
    "form.email": "Email",
    "form.emailPlaceholder": "email",
    "form.password": "Password",
    "form.passwordPlaceholder": "password",
    "form.newPassword": "New password",
    "form.newPasswordPlaceholder": "new password",
    "form.confirmPasswordPlaceholder": "confirm password ",
    "form.confirmPassword": "Confirm password ",
    "form.currentPassword": "Current password",
    "form.currentPasswordPlaceholder": "current password",
    "form.firstnamePlaceholder": "firstname",
    "form.firstname": "Firstname",
    "form.firstnamePlaceholder": "firstname",
    "form.lastname": "Lastname",
    "form.lastnamePlaceholder": "lastname",
    "form.merchantCompany": "Merchant company",
    "form.company": "Company",
    "form.companyPlaceholder": "company",
    "form.merchantContactEmail": "Merchant contact email",
    "form.reset": "Reset",
    "form.all": "All",
    "form.vendorMerchantId": "Vendor merchant",
    "form.vendorContactEmail": "Vendor contact email",
    "form.vendorContactPhone": "Vendor contact phone",
    "form.phonePlaceholder":"phone",
    "form.merchantId": "Merchant",
    "form.vendorCode": "Vendor code",
    "form.vendorCodePlaceholder": "vendor code",
    "form.vendorName": "Vendor name",
    "form.vendorNamePlaceholder": "vendor name",
    "form.isMarketplace": "Is market place",

    "login.panelName": "Login",
    "login.loginBtn": "Login",
    "login.panelRightSpan": "Don’t have an account?",
    "login.panelRightSpanButton": "Register a new account",
    "login.rememberMeLabel": "Remember me",
    "login.forgetBtn": "Forget the password ?",

    "passwordForget.passwordReset": "Password Reset",
    "passwordForget.panelRightSpan": "We Will Help You Reset your Password",
    "passwordForget.rememberedPassword": "Remembered your Password?",
    "passwordForget.navigateBack": "Back to Sign In",
    "passwordForget.label_code": "Verification Code",
    "passwordForget.placeholder_code": "Verification Code",

    "passwordReset.passwordReset": "Password Reset",
    "passwordReset.panelRightSpan": "We Will Help You Reset your Password",
    "passwordReset.rememberedPassword": "Remembered your Password?",
    "passwordReset.navigateBack": "Back to Sign In",
    "passwordReset.passwordNotMatch": "The new password that you entered do not match!",

    "passwordResetSuccess.successful": "Successful",
    "passwordResetSuccess.panelRightSpan": "The password has been changed successfully",
    "passwordResetSuccess.navigateBack": "Back to Sign In",

    "router.dashboard": "Dashboard",
    "router.customer": "Customer",
    "router.customerSegment": "Customer Segment",
    "router.customerAttribute": "Customer Attribute",
    "router.customerSubscribe": "Customer Subscribers",
    "router.customerGroup": "Customer Group",
    "router.campaigns": "Campaigns",
    "router.campaignSettings": "Campaign Settings",
    "router.whatsappTemplate": "Whatsapp Template",
    "router.reports": "Reports",
    "router.orders": "Orders",
    "router.ordersConfig": "Order Config",
    "router.promotionSettings": "Promotion Settings",
    "router.login": "Login",
    "router.forgetpassword": "Forget Password",
    "router.resetpassword": "Reset Password",
    "router.passwordResetSuccess": "Reset Password Success",
    "router.merchantUsers": "Users",
    "router.merchantVendor":"Vendor",
    "router.settings": "Settings",
    "router.pageNotFound": "Page Not Found",
    "router.marketplaces":"marketplaces",
    "router.PageBuilder":"Page Builder",

    "homeMenu.dashboard": "Dashboard",
    "homeMenu.customer": "Customer",
    "homeMenu.allCustomers": "All Customers",
    "homeMenu.segment": "Segment",
    "homeMenu.customerAttribute": "Attribute",
    "homeMenu.customerSubscribe": "Subscribers",
    "homeMenu.customerSegment": "Customer Segment",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.journeys": "Journeys",
    "homeMenu.journey": "Journey",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.whatsappTemplate": "Whatsapp Template",
    "homeMenu.wTemplateCreate": "Create Template",
    "homeMenu.promotion": "Promotion",
    "homeMenu.orders": "Orders",
    "homeMenu.customerConfig": "Config",
    "homeMenu.allOrders": "All Orders",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "Main",
    "homeMenu.push": "Push",
    "homeMenu.company": "Company",
    "homeMenu.role": "Role",
    "homeMenu.user": "Users",
    "homeUser.loginout": "Sign Out",
    "homeMenu.merchantUsers": "Merchant Users",
    "homeMenu.PageBuilder": "Page Builder",
    "homeMenu.allPageBuilder": "All Pages",
    "homeMenu.reward": 'Reward',
    "homeMenu.warranty": "Warranty",
    "homeMenu.warrantyRecord": "Warranty Records",
    "homeMenu.warrantyProduct": "Products Management",
    "homeMenu.warrantyForm": "Registration Form",

    "DashboardHeader.labelTips": "Let’s get creative with E-Com365 Go CRM Micro-service to organize your content.",

    "Customer.pushBtn": "Push Message",
    "Customer.pushPromoBtn": "Push Promotion",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "click the button to select company",
    "Customer.addCustomerTitle": "Create Customer",
    "Customer.editCustomerTitle": "Edit Customer",
    "Customer.detailCustomerTitle": "Detail Customer",
    "Customer.selectCompanyTitle": "Select Company",
    "Customer.editPushMessage": "set push message",
    "Customer.pushModal.okBtn": "Send",
    "customer.confirmModalDelMsg": "Are you sure to delete {name} with {key} equal {val}?",
    "customer.selectCompanyWarning": "please select company!",
    "customer.confirmModalPromoMsg": "Are you sure to push promotion message to these {val} users?",
    "customer.addFirstCustomerTitle": "Add First Customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add Customer",
    "customer.addFirstCustomerReadMore": "Read More",

    "createCustomer.backLabel": "Back",

    "detailCustomer.editInfoButton": "Edit information",

    "Attribute.createAttributeTitle": "Create Attribute",
    "Attribute.editAttributeTitle": "Edit Attribute",
    "Attribute.detailAttributeTitle": "Attribute Detail",

    "Segment.addSegmentTitle": "Create Segment",
    "Segment.detailSegmentTitle": "Detail Segment",
    "Segment.editSegmentTitle": "Edit Segment",
    "Segment.confirmModalDelMsg": "Are you sure to delete segment data with id equal {val}?",

    "Subscribe.createTitle": "Create Subscriber",
    "Subscribe.editTitle": "Edit Subscriber",
    "Subscribe.DetailTitle": "Detail Subscriber",

    "campaigns.addNewCampaignLabel": "Create New Campaigns",
    "campaigns.saveButton": "Save Campaign",
    "campaigns.editCampaignLabel": "Edit The Campaign",
    "campaigns.sendCampaignLabel": "Send Campaign",
    "campaigns.addNewJourneyLabel": "Create New Journey",
    "campaigns.saveJourneyButton": "Save Journey",
    "campaigns.editJourneyLabel": "Edit The Journey",
    "campaigns.sendJourneyLabel": "Send Journey",

    "whatsappTemplate.addNewDataLabel": "Create New Template",

    "Order.addOrderTitle": "Create Order",
    "Order.detailOrderTitle": "Information",
    "Order.OrderInvoiceTitle": "Invoice",
    "Order.OrderShipmentTitle": "Shipment",
    "Order.editOrderTitle": "Edit Order",
    "Order.addFirstOrderTitle": "No Orders Yet",
    "Order.addFirstOrderLabel1": "All the upcoming orders from your store will be visible in this page.",
    "Order.addFirstOrderLabel2": "You can add orders by yourself if you sell offline.",
    "Order.addFirstOrder": "Add Order",

    "rewardPoints.rewardRules": "Reward Rules",
    "rewardPoints.createRewardRuleTitle": "Create Rule",
    "rewardPoints.editRewardRuleTitle": "Detail Rule",
    "rewardRule.confirmModalDelMsg": "Are you sure to delete rule data with id equal {val}?",

    "Config.createTitle": "Create Config",
    "Config.editTitle": "Edit Config",

    "PageBuilder.detailPageTitle": "Detail Page",
    "PageBuilder.addPageTitle": "Create Page",

    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer Growth",
    "exports.salesGoal": "Sales Goal",
    "exports.conversionRate": "Conversion Rate",
    "exports.storeFunnel": "Store Funnel",
    "exports.ageDistribution": "Age Distribution",
    "exports.averageOrderValue": "Average Order Value",

    "company.addCompanyTitle": "Create Company",
    "company.editCompanyTitle": "Edit Company",
    "company.confirmModalDelMsg": "Are you sure to delete company data with id equal {val}?",

    "Message.PushDetailTitle": "Push Detail",
    "Message.PushTargetTitle": "Push Target",
    "Message.notFoundDetail": "Message Not Found",
    "MessageModal.text_isRequired": "Please input a text or delete this field.",

    "pushModal.addTextBtn": "add text",
    "pushModal.addImageBtn": "Upload Image",
    "pushModal.addImagePlaceHolder": "input the image link or upload the file(png/jpg/jpeg), multiple links are separated by [ , ]",
    "pushModal.addWeImagePlaceHolder": "input the image link or upload the file(png/jpg), multiple links are separated by [ , ]",
    "pushModal.uploadImageTypeError": "{name} is not a valid image",
    "pushModal.addVideoBtn": "Upload Video",
    "pushModal.addVideoPlaceHolder": "input the video link or upload the file(mp4/avi/mov/flv), multiple links are separated by [ , ]",
    "pushModal.addWeVideoPlaceHolder": "input the video link or upload the file(mp4), multiple links are separated by [ , ]",
    "pushModal.uploadVideoTypeError": "{name} is not a valid video",
    "pushModal.addDocumentBtn": "Upload Document",
    "pushModal.addDocumentPlaceHolder": "input the document link or upload the file(doc/docx/pdf/xls/xlsx/ppt/pptx), multiple links are separated by [ , ]",
    "pushModal.uploadDocumentTypeError": "{name} is not a valid document",
    "pushModal.uploadSize": "max size cannot exceed 30MB",
    "pushModal.uploadFileTypeError": "{name} is not a valid file",

    "role.addRoleTitle": "Create Role",
    "role.editRoleTitle": "Edit Role",
    "role.confirmModalDelMsg": "Are you sure to delete role data with RoleName equal {val}?",

    "user.addUserTitle": "Create User",
    "user.editUserTitle": "Edit User",
    "user.confirmModalDelMsg": "Are you sure to delete user data with Uid equal {val}?",

    "merchantUsers.users": "Users",
    "merchantUsers.role": "Role",
    "merchantUsers.email": "Email",
    "merchantUsers.createdAt": "Account Created",
    "merchantUsers.updatedAt": "Last Modified",
    "merchantUsers.action": "Action",
    'merchantUsers.addUserTitle': "Create User",
    'merchantUsers.editUserTitle': "Edit User",
    "merchantUsers.addBtn": "Add one user",
    "merchantUsers.manageRolePermissionsBtn": "Manage role permissions",
    "merchantUsers.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantUsers.filterBtn": "Filter",
    "merchantUsers.first_name": "Firstname",
    "merchantUsers.last_name": "Lastname",
    "merchantUsers.email": "Email",
    "merchantUsers.role_id": "Role",
    "merchantUsers.merchant_id": "Merchant company",
    "merchantUsers.activeFilters": "Active Filters:",
    "merchantUsers.merchantCompany": "Merchant Company",
    "merchantUsers.deleteMerchantUser": "Delete Merchant User",
    "merchantUsers.confirmModalDelMsg": "Are you sure to delete merchant user data with email equal {email}?",
    "merchantUsers.permissionTitle": "{userName}'s permission",
    "merchantUser.permissons": "Permissons",

    "merchantsVendor.addBtn": "Create New Service Vendor",
    "merchantsVendor.filterBtn": "filter",
    "merchantsVendor.activeFilters": "Active Filters:",
    'merchantsVendor.addMerchantTitle': "Create service vendor",
    'merchantsVendor.editMerchantTitle': "Edit service vendor",
    "merchantsVendor.createdAt": "Instance Created",
    "merchantsVendor.updatedAt": "Last Modified",
    "merchantsVendor.action": "Action",
    "merchantsVendor.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantsVendor.active": "Active",
    "merchantsVendor.instanceId": "Instance Id",
    "merchantsVendor.serviceId": "Merchant Service",
    "merchantsVendor.serviceStartDate": "Service Start Date",
    "merchantsVendor.serviceEndDate": "Service End Date",
    "merchantsVendor.vendorId": "Vendor Id",
    "merchantsVendor.vendorCode": "Vendor code",
    "merchantsVendor.vendorName": "Vendor name",
    "merchantsVendor.vendorContactEmail": "Vendor contact email",
    "merchantsVendor.vendorContactPhone": "Vendor contact phone",
    "merchantsVendor.frontendApiGateway": "Frontend api gateway",
    "merchantsVendor.vendorMerchantId": "Vendor Merchant",
    "merchantsVendor.merchantId": "Merchant",
    "merchantsVendor.vendor_merchant_id": "Vendor merchant",
    "merchantsVendor.merchant_id": "Merchant",
    "merchantsVendor.vendor_name": "Vendor name",
    "merchantsVendor.vendor_code": "Vendor code",
    "merchantsVendor.deleteMerchantServiceInstance": "Delete Instance",
    "merchantsVendor.confirmModalDelMsg": "Are you sure to delete vendor data with vendor id equal {vendorId}?",

    "marketplaces.merchantCode":"Merchant Code",
    "marketplaces.merchantCompany":"Merchant Company",
    "marketplaces.frontendUrl":"Frontend Url",
    "marketplaces.action":"Action",
    "marketplaces.login":"login",

    "settings.settings": "Settings",
    "settings.security": "Security",
    "settings.busiessProfile": "Business Profile",
    "settings.profile": "My Profile",
    "settings.accounts": "Accounts",
    "setting.passwordNewAndOldSame": "The new password can't equal current password!",
    "setting.passwordNotMatch": "The new password that you entered do not match!",
    "settings.passwordChange": "Change password  success",

    "profile.save": "Save",
    "profile.saveProfileSuccess": "Save profile  success",

    "warrantyForm.formTitle": "Registration Form Management",
    "warrantyForm.formPreviewLabel": "Preview",
    "warrantyForm.formEditLabel": "Edit",
    "warrantyForm.categoryPlaceholder": "- Select product category -",
    "warrantyForm.seriesPlaceholder": "- Select product series -",
    "warrantyForm.skuPlaceholder": "- Model No.(SKU) -",
    "warrantyForm.uploadBtn": "Upload files",
    "warrantyForm.uploadSizeTips": "Files must be less than 5MB.",
    "warrantyForm.uploadTypeTips": "Allowed file types: png gif jpg jpeg pdf.",
    "warrantyForm.isEmail": "Please enter a valid email account"

}
export {
    en_US
}   