import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './landingWelcome.module.css';
import leftPic from '../../static/loading/left_pic.png'

const LandingWelcome = props => {

    return (
        <div className={classes.root}>
            <div className={classes.left_top}>
                <h1>
                    <FormattedMessage
                        id="global.systemLandingTitle"
                        defaultMessage={"Welcome back to Merchant portal"}
                    />
                </h1>
                <p>
                    <FormattedMessage
                        id="global.systemLandingMessage"
                        defaultMessage={"We will provide you with advanced AI business analysis and powerful CRM system!"}
                    />
                </p>
            </div>
            <div className={classes.left_bottom}>
                <img src={leftPic} alt='' />
            </div>
        </div>
    )
}

export default LandingWelcome;