import axios from 'axios';
import { message } from 'antd';

//let rootUrl = process.env.REACT_APP_BASE_URL;
let rootUrl = '/api/';
let showMsg = false;
if(process.env.NODE_ENV == "production") {
    rootUrl= '/';
}

function getToken() {
    return localStorage.getItem('LOGIN_TOKEN');
}

axios.interceptors.request.use(
    config => {

        const token = getToken()
        // if (config.url.indexOf('/login') > -1) {
        //     config.headers.Authorization = ''
        // } else {
        config.headers.Authorization = `Bearer ${token ? token : ''}`
        // }
        config.headers['Content-Type'] = "application/json";
        const { url } = config
        if (config.method === 'get') {
            //  给data赋值以绕过if判断
            config.data = true
        }
        else if (config.method === 'delete') {
            config.data = {
                data: true
            }
        }
        //const whiteList = url.includes("/customer");
        const whiteList = true;
        if (/^\/api\//.test(url) && !token && !window.location.href.indexOf('/login') > -1 && !whiteList) {
            // window.location.hash="/login"// 跳转到登录页

            // window.location.href = process.env.NODE_ENV == "production" ? `${process.env.REACT_APP_FRONTEND_URL}/login` : `${window.location.origin}/${process.env.REACT_APP_BASE_NAME}/login`;

            window.location.href = `${window.location.origin}/login`;
        }


        const subscribedServiceList = localStorage.getItem('subscribedServiceList') ? JSON.parse(localStorage.getItem('subscribedServiceList')) : []

        const match = subscribedServiceList.find(service => {
            return service.serviceCode == config.serviceCode
        })

        if (config.serviceCode == 'default') {

            config.headers['api-gateway'] = process.env.NODE_ENV == "production" ? window.location.origin : process.env.REACT_APP_BACKEND_URL;

        } else if(match){
            config.headers['api-gateway'] = match ? encodeURI(match.apiGateway) : '';

        }else{
            console.error('service not match api')
        }

        if(process.env.NODE_ENV == "production") {
            const prod_gateway = config.headers['api-gateway'].split('/api');
            if(prod_gateway?.length > 1) {
                const new_config_url = config.url.replace('/api', '');
                config.url = `/api${prod_gateway[1] ? prod_gateway[1] : ''}${new_config_url}`;
            }
            else {
                config.url = '/api' + config.url;
            }
        }

        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

axios.interceptors.response.use(response => {
    if (response.data.code === 401) {
        if (!showMsg) {
            showMsg = true;
            message.error(response.data.msg);
            setTimeout(() => {
                showMsg = false;
            }, 3000);
        }
        // window.location.hash="/login";
        // window.location.href = process.env.NODE_ENV == "production" ? `${process.env.REACT_APP_FRONTEND_URL}/login` : `${window.location.origin}/${process.env.REACT_APP_BASE_NAME}/login`;

        window.location.href = `${window.location.origin}/login`;

    }
    return response;
}, (error) => {
    if (!showMsg) {
        showMsg = true;
        //message.error(error.response.data.error);
        setTimeout(() => {
            showMsg = false;
        }, 3000);
    }
    if (error.response.status === 401) {
        // window.location.hash="/login";
        const pathname = window.location.pathname
        if (!pathname.includes("/login") && !pathname.includes("/forgetpassword") && !pathname.includes("/resetpassword") && !pathname.includes("/resetpassword/success") && !error.request.responseURL.includes("/login")) {

            // window.location.href = process.env.NODE_ENV == "production" ? `${process.env.REACT_APP_FRONTEND_URL}/login` : `${window.location.origin}/${process.env.REACT_APP_BASE_NAME}/login`;

            window.location.href = `${window.location.origin}/login`;


        }
    }
    return Promise.reject(error);
}
)

//请求封装
export function get(url, param, serviceCode) {

    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.get(rootUrl + url, { params: param, serviceCode: serviceCode },).then(response => {
            resolve(response.data)
        }, err => {
            reject(err)
        }).catch((error) => {
            reject(error)
        })
    })
}

export function post(url, params, serviceCode) {

    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.post(rootUrl + url, params, { serviceCode: serviceCode }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}

export function put(url, params, serviceCode) {

    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.put(rootUrl + url, params, { serviceCode: serviceCode }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}

export function deleted(url, params, serviceCode) {

    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.delete(rootUrl + url, { params: params, serviceCode: serviceCode }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}