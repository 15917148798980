
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { userLogin, getProfile } from '../../api/loginApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserInfo, clearToken, clearUserInfo } from '../../../redux/userSlice'
// import { getPermissionsList } from '../../api/roleApi';

export const useHomeUser = props => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const loginOut = useCallback(() => {
        localStorage.removeItem("LOGIN_TOKEN");
        dispatch(clearToken())
        dispatch(clearUserInfo())
        // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login`
        window.location.href = `${window.location.origin}/login`;
    }, [navigate]);

    return {
        loginOut
    };

}